/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import styles from "./CommentForm.module.scss"
import {AssignableUserAttributes} from "typing/dto";
import { ReactComponent as IconInfoCircle } from "assets/icons/info-circle.svg"
import Tooltip from '@mui/material/Tooltip';
import { VisibilityToggle } from "components/visibility-toggle/VisibilityToggle";
import { LoaderButton } from "components/loader-button/LoaderButton";
import classNames from "classnames";
import { VisibilityStatusEnum, EVENT_LOG_DATA } from "appConstants";
import { enqueueError, getErrorMessage } from "utils/message";
import {MentionsInput, Mention, SuggestionDataItem} from 'react-mentions';
import {DataType} from "typing/request";
import replace from "lodash/replace";
import {TicketingService} from "services/TicketingService";
import useCallbackWithDebounce from "hooks/useCallbackWithDebounce";

interface ICommentFormProps {
    maxLength?: number;
    placeholder?: string | Map<VisibilityStatusEnum, string>;
    onAccept: (text:string, visibility: VisibilityStatusEnum) => Promise<void>;
    onCancel?: () => void,
    onChange?: (text:string) => void,
    title?: string;
    className?: string;
    acceptBtnLabel?: string | Map<VisibilityStatusEnum, string>;
    disabled?: boolean;
}

export interface ItemType extends SuggestionDataItem {
    internalDisplay: string;
}

// services
const ticketingService = TicketingService.getInstance();

export const CommentForm: React.FC<ICommentFormProps> = ({
    title = "Comment",
    maxLength = 5000,
    onAccept,
    onCancel,
    onChange,
    placeholder = "Write a comment...",
    className,
    acceptBtnLabel = "Accept",
    disabled = false,
}) => {
    
    const [visibility, setVisibility] = useState(VisibilityStatusEnum.INTERNAL);
    const [text, setText] = useState("");
    const tipsMap = new Map<VisibilityStatusEnum, string>([
        [VisibilityStatusEnum.INTERNAL, "Comments are only visible to Staff. To mark comments to be visible to a Homeowner, edit the ticket to mark it visible to Staff & Owner."],
        [VisibilityStatusEnum.OWNER, "Comments are visible to Homeowner. To mark comments to be only visible to Staff , edit the ticket to mark it visible to Staff Only."]
    ]);

    const acceptLabel: string = typeof acceptBtnLabel === "string" ? acceptBtnLabel : (acceptBtnLabel.get(visibility) || "");
    const placeholderLabel: string = typeof placeholder === "string" ? placeholder : (placeholder.get(visibility) || "");

    const onToggleUpdate = (value: VisibilityStatusEnum) => setVisibility(value);

    const onTextChange = (value: string) => {
        setText(value);
        if (onChange) onChange(value);
    };

    const cleanSuggestionTags = (text: string): string => {
        return replace(text, /@\[(.+?)\]\(\d+\)/g, '@$1');
    };

    const onSubmit = async() => {
        await onAccept(cleanSuggestionTags(text), visibility);
        setText("");
    };

    // Method to fetch assignable users and set in options to auto complete component
    const fetchSuggestions = async (search: string) : Promise<any> => {
        try {
            const response = await ticketingService.getAssignees({
                search_term: search
            }, {}, {requestCancellation: true});
            return response?.data.map((user: DataType<AssignableUserAttributes>): ItemType => ({
                id: Number(user.id),
                display: user.attributes.username,
                internalDisplay: `${user.attributes.first_name} ${user.attributes.last_name}`
            }));
        } catch (error) {
            enqueueError({logInfo: EVENT_LOG_DATA.TICKET_ASSIGNABLE_USER_GET, error: Error(getErrorMessage(error))});
            return [];
        }
    };

    const search = useCallbackWithDebounce(async (searchString: string, callback: Function): Promise<any> => {
        if (searchString === null) return;
        if (searchString.length <= 2) {
            callback([]);
        } else {
            const suggestions = await fetchSuggestions(searchString);
            callback(suggestions)
        }
    })

    const handleInputRef = (element: HTMLTextAreaElement) => {
        if (element) element.setAttribute('data-testid', 'comment-textarea');
    }

    return (
        <div data-testid="comment-form" className={classNames(styles.content, className)}>
            <h2>{title}</h2>
            <div>
                <div className={styles["content-toggle"]}>
                    {/* Selector */}
                    <VisibilityToggle onUpdate={onToggleUpdate} defaultVisibility={VisibilityStatusEnum.INTERNAL} />
                    
                    {/* Info Badge */}
                    <Tooltip title={tipsMap.get(visibility)}>
                        <a className={styles.more} >
                            <span className={styles["info-icon"]}><IconInfoCircle /></span>
                            Learn more
                        </a>
                    </Tooltip>
                </div>
                
                {/* Input */}
                <div className={styles["content-bottom"]}>
                    <MentionsInput
                        value={text}
                        onChange={(e) => onTextChange(e.target.value)}
                        placeholder={placeholderLabel}
                        maxLength={maxLength}
                        className={styles.textarea}
                        inputRef={handleInputRef}
                    >
                        <Mention
                            trigger={"@"}
                            data={search}
                            displayTransform={(_, display) => `@${display}`}
                            // @ts-ignore
                            renderSuggestion={(_suggestion: ItemType, _search, _highlightedDisplay) => (
                                <div className={styles["suggestions-item"]}>{_suggestion.internalDisplay}</div>
                            )}
                            appendSpaceOnAdd={true}
                        />
                    </MentionsInput>
                    <div className={classNames(styles.submit)}>
                        <span className={styles["word-count"]}>{text.length}/{maxLength}</span>
                        <span>
                            {onCancel && <LoaderButton id="comment-form-cancel-btn" onClick={() => onCancel()}>Cancel</LoaderButton>}
                            <LoaderButton id="comment-form-accept-btn" disabled={disabled} variant="primary-inverse" onClick={onSubmit}>{acceptLabel}</LoaderButton>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
